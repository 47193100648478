@import '~src/assets/css/variables';

.lozenge-drop-down {
  .mat-form-field-infix {
    display: flex;
    flex-wrap: wrap;
    flex: 1 100px;
  }

  input[type=text] {
    flex: 1 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.discount-code-lozenge {
  background-color: #c8d8e3;
  color: #000;
  font-size: 0.8rem;
  border-radius: 2px;
  padding: 3.75px 5px;

  &.mat-standard-chip {
    background-color: #c8d8e3;
    border-radius: 2px;
    border: 1px solid #000;
    min-height: 24px;
    padding: 5px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.item-status-lozenge {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  padding: 4px 8px 4px 6px;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.9rem;
  /*min-height: 29px;*/
  vertical-align: middle;

  font-weight: bold;

  line-height: normal;
  white-space: nowrap;

  &.item-status-lozenge--pre-operational {
    border-left: 4px solid #46bdc6;
  }

  &.item-status-lozenge--operational-broken {
    border-left: 4px solid #0a24fb;
    text-decoration: line-through;
    text-decoration-color: #d12323;
  }

  &.item-status-lozenge--operational-ready {
    border-left: 4px solid #0a24fb;
    border-right: 4px solid #00b400;
  }

  &.item-status-lozenge--operational-checkedout {
    border-left: 4px solid #0a24fb;
    border-right: 4px solid #d12323;
  }

  &.item-status-lozenge--operational {
    border-left: 4px solid #0a24fb;
  }

  &.item-status-lozenge--post-operational {
    border-left: 4px solid #20124d;
  }

  &.item-status-lozenge--suspended {
    border-left: 4px solid #808080;
  }
}

.res-location-lozenge {
  background-color: $grey-dark;
  color: #000;
  font-size: 0.8rem;
  border-radius: 2px;
  padding: 3.75px 5px;

  &.mat-standard-chip {
    background-color: #c8d8e3;
    border-radius: 2px;
    border: 1px solid $green;
    min-height: 24px;
    padding: 5px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.location-lozenge {
  background-color: $grey-dark;
  border: 2px solid $green;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 8px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  height: 28px;
  line-height: normal;

  &.mat-standard-chip {
    border-radius: 2px;
  }
}

.price-group-lozenge {
  background-color: $grey-dark;
  border: 2px solid $orange;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 5px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  height: 28px;
  line-height: normal;
  margin-right: $margin-right;



  mat-icon {
    font-size: 1rem;
    height: 16px;
    width: 16px;
    vertical-align: top;
  }

  &.price-group-lozenge--input {
    margin-top: -2px;
     margin-bottom: -4px;
     font-size: 0.75rem;
     height: auto;
     padding-top: 3px;
     padding-bottom: 3px;

    mat-icon {
      font-size: inherit;
      height: auto;
      width: 12px;
      vertical-align: middle;
    }
   }

  &.mat-standard-chip {
    border-radius: 2px;
  }

  &.price-group-lozenge--cursor-pointer {
    cursor: pointer;
    user-select: none;
  }

  &.price-group-lozenge--invalid {
    color: white;
    border-color: $red;
    background-color: $red;
  }

  &:focus {
    background-color: $grey-focused;
  }
}

.activity-lozenge {
  background-color: #ffe4c4;
  border: 2px solid $colour-category;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 8px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  line-height: normal;
  margin: 6px;
  width: fit-content;
  cursor: pointer;

  &.mat-standard-chip {
    background-color: #ffe4c4;
    border: 2px solid $colour-category;
    border-radius: 2px;
  }

  &:hover {
    background-color: #ffbb6a;
  }

  &.activity-lozenge--select {
    border-color: $colour-primary;
    background-color: $colour-primary;
    color: #FFF;
  }
}

.category-lozenge {
  background-color: $grey-dark;
  border: 2px solid $colour-category;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 8px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  line-height: normal;
  margin: 6px;
  width: fit-content;
  cursor: pointer;

  &.mat-standard-chip {
    background-color: $grey-dark;
    border: 2px solid $colour-category;
    border-radius: 2px;
  }

  &:hover {
    background-color: $grey-darker;
  }

  &.category-lozenge--select {
    border-color: $colour-primary;
    background-color: $colour-primary;
    color: #FFF;
  }
}

.offering-group-lozenge {
  background-color: $colour-category;
  border: 2px solid $colour-category;
  color: white;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 8px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  line-height: normal;
  margin: 6px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: $blue-pale;
  }

  &.offering-group-lozenge--select {
    border-color: $colour-primary;
  }
}

.channel-lozenge {
  color: $white;
  background-color: $colour-category;
  border: 2px solid $grey-dark;
  border-radius: 2px;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 4px 8px 4px 6px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  line-height: normal;
  margin: 6px;
  width: fit-content;
  cursor: pointer;

  &.mat-standard-chip {
    color: $white;
    background-color: $colour-category;
    border: 2px solid $grey-dark;
    border-radius: 2px;
  }

  &:hover {
    background-color: $grey-darker;
  }

  &.channel-lozenge--select {
    border-color: $colour-primary;
    background-color: $colour-primary;
    color: #FFF;
  }
}


/*** HOME & HEADER STATUS HEADERS ***/

.store-status-lozenge {
  align-self: center;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 3px;
  background-color: #e48027;
  color: white;
  padding: 5px 8px;

  cursor: pointer;

  user-select: none;

  text-transform: uppercase;

  &.store-status-lozenge--locations {
    color: $colour-black;
    font-weight: bold;
    background-color: $grey-dark;
    border: 2px solid $green;
  }

  &.store-status-lozenge--locations--restricted {
    color: $colour-black;
    font-weight: bold;
    background-color: $grey-dark;
    border: 2px solid $orange;
  }

  &.store-status-lozenge--brm1 {
    color: orange;
    background-color: white;
    font-weight: bold;
    font-size: 15px;
  }

  &.store-status-lozenge--free {
    background-color: #00008b;
  }

  &.store-status-lozenge--dev {
    background-color: #964B00;
  }

  &.store-status-lozenge--trial {
    background-color: #48d1cc;

    .mat-badge-content {
      background-color: #007bff;
    }
  }

  &.store-status-lozenge--ready {
    background-color: #28a745;
  }

  &.store-status-lozenge--live {
    background-color: #007bff;;
  }

  &.store-status-lozenge--mat-badge-override {
    .mat-badge-content {
      background-color: #007bff;
    }
  }

  &.store-status-lozenge--mat-badge-override-red {
    .mat-badge-content {
      background-color: $red !important;
    }
  }

}

/*** END OF HOME & HEADER STATUS HEADERS ***/
