.brm-input-rdp {
  background: rgba(211, 211, 211, 0.5) !important;
  border-radius: 4px !important;
  font-size: 14px;
  border: none;
  padding: 0px 5px;
  margin-top: 10px;

  &.brm-input-rdp--error {
    &::placeholder {
      color: $red;
    }
  }

  &.brm-input-rdp--no-margin-top {
    margin-top: 0px;
  }

  &.brm-input-rdp--mat {
    vertical-align: top;

    .mat-form-field-underline {
      background-color: transparent;
      height: 0px;
    }

    .mat-form-field-ripple {
      background-color: transparent;
    }

    .placeholder {
      // display: none;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
      border-top: 0px;
      padding-top: 6px;
      padding-bottom: 6px;
      /*padding-top: 0px;
      padding-bottom: 0px;*/
    }

    input.mat-chip-input {
      width: 100%;
      min-width: 20px;
      margin: 4px;
      flex: 1 1;
    }
  }
}

.brm-input-loader {
  position: relative;

  .brm-input-loader__loader {
    position: absolute;
    right: 5px;
    align-self: center;
  }
}

.brm-input-grey {
  position: relative;
  background-color: $grey-input;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  padding: 0px 5px;
  display: flex;

  &.brm-input-grey--loader {
    padding-right: 25px;
  }

  input[type=text] {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: transparent;
    outline: 0;
  }

  .brm-input-grey__loader {
    position: absolute;
    right: 5px;
    align-self: center;
  }
}
