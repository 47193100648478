.topLine {
  border-top: 1px solid black;
}

.topLineBig {
  border-top: 2px solid black;
}

.bottomLine {
  border-bottom: 2px solid black;
}

.price {
  text-align: right;
}

.brm-row {
  width: 800px;
}

table #shop-name {
  font-size: 48px;
}
