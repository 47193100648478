@import '~src/assets/css/variables';

.cursor-pointer {
  cursor: pointer;
}

.margin-btm-av {
  margin-bottom: $margin-btm;

  &.margin-btm-av--extra {
    margin-bottom: ($margin-btm + 10px);
  }

  &.margin-btm-av--form {
    margin-bottom: ($margin-btm + 5px);
  }

  &.margin-btm-av--only-mob {
    margin-bottom: $margin-btm;
  }
}

.margin-right-av {
  margin-right: $margin-right;

  &.margin-right-av--important {
    margin-right: $margin-right !important;
  }
}

.margin-left-av {
  margin-left: $margin-left;

  &.margin-left-av--important {
    margin-left: $margin-left !important;
  }
}

.margin-top-av {
  margin-top: $margin-btm;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.prefix-icon {
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  background-size: 18px 18px;
}

.product-type-img {
  cursor: pointer;

  vertical-align: middle;
  margin-right: 5px;
  min-width: 25px;
  min-height: 25px;

  background-image: url('~src/assets/images/inventory/shape/product-category-active.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25px 25px;

  &.product-type-img--small {
    min-width: 19px;
    min-height: 19px;
    background-size: 19px 19px;
  }

  &.product-type-img__category {
    background-image: url('~src/assets/images/inventory/shape/product-category-active.svg');
  }

  &.product-type-img__family {
    background-image: url('~src/assets/images/inventory/shape/product-family-active.svg');
  }

  &.product-type-img__line {
    background-image: url('~src/assets/images/inventory/shape/product-line-active.svg');
  }

  &.product-type-img__line-virtual {
    background-image: url('~src/assets/images/inventory/shape/product-line-virtual.svg');
  }

  &.product-type-img__item {
    background-image: url('~src/assets/images/inventory/shape/product-item-active.svg');
  }

  &.product-type-img__price-groups {
    background-image: url('~src/assets/images/inventory/shape/price-groups.svg');
  }

  &.product-type-img__package {
    background-image: url("~src/assets/images/inventory/shape/package.svg");
    background-size: 36px 36px;
    background-position-y: -11px;
  }

  &.product-type-img--cursor-def {
    cursor: default;
  }

  &.product-type-img--margin-left {
    margin-left: $margin-left;
  }

  &.product-type-img--margin-right {
    margin-right: $margin-right;
  }

  &.product-type-img--inline-block {
    display: inline-block;
  }

  &.product-type-img--grey {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

.brm-language-field {
  color: rgb(124, 157, 198);

  .angular-editor-placeholder, .mat-form-field-label {
    color: inherit;
  }
}

.lang-field {
  color: rgb(124, 157, 198);

  .angular-editor-placeholder, .mat-form-field-label {
    color: inherit;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.context-help {
  margin-bottom: $margin-btm;
  border-radius: 3px;
  padding: 6px 12px;
  background-color: #eeeeee;
  font-size: 12px;
  overflow-y: auto;

  &.context-help--no-margin {
    margin-bottom: 0px;
  }
}

.loader {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loader__spinner {

  }
}

.span-icon {
  mat-icon {
    vertical-align: bottom;
  }
}

.read-only-lozenge {
  background-color: #dc983e;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 0px auto 10px auto;
  padding: 15px;
  max-width: 175px;
  text-align: center;
  font-weight: bold;
}

.coming-soon-lozenge {
  background-color: $colour-black;
  color: #dc983e;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 0px auto 10px auto;
  padding: 15px;
  max-width: 175px;
  text-align: center;
  font-weight: bold;
}

.upgrade-lozenge {
  color: $colour-black;
  border: 4px solid #dc983e;
  margin: 0px auto 10px auto;

  padding: 15px;

  max-width: 50%;
  max-width: 700px;
  text-align: center;
}


.brm-tooltip {
  text-align: left;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #283743;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.brm-error-message {
  color: $red;
  font-weight: bold;
  text-align: center;
}

.brm-pl {
  color: $brm-pl-colour;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .margin-btm-av {
    &.margin-btm-av--only-mob {
      margin-bottom: 0px;
    }
  }
}
