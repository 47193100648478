.general-info {
  .mat-form-field-infix {
    font-size: 14px;
  }
}

.cursor-default {
  cursor: default;
}

.mat-pseudo-checkbox-checked::after {
  top: 3.8px !important;
  left: 2px !important;
}

.fc-toolbar {
  float: left !important;
  width: 100% !important;
}

.fc-timeline {
  float: left;
}

.fc-license-message {
  display: none !important;
}

.fc-view {
  float: left;
  width: 100%;
}

.fc-timeline-event {
  margin: 0 !important;
  color: #fff !important;
  font-size: 12px;
}

.fc-button-primary:focus,
.fc-button:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.margin-top-15 {
  margin-top: 15px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-56 {
  padding-top: 56px;
}

.display-none {
  display: none !important;
}

.width-10 {
  width: 10%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-50 {
  width: 50%;
}

.width-70 {
  width: 70%;
}

.width-90 {
  width: 90%;
}

.width-100 {
  width: 100%;
}

.font-14 {
  font-size: 14px;
}

.padding-0 {
  padding: 0 !important;
}

.padding-15 {
  padding: 15px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-left-2-5-rem {
  padding-left: 2.5rem !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-left-13 {
  margin-left: 13px;
}

.default-theme .mat-chip.mat-standard-chip::after {
  background: transparent;
}

.display-block {
  display: block;
}

.time-picker-header,
.time-picker-clock > button.active,
.time-picker-clock-origin,
.time-picker-clock-arrow {
  background: #deaf2f !important;
}

.time-picker-selected-time,
.time-picker-selected-ampm,
.time-picker-selected-time div.selected,
.time-picker-selected-ampm div.selected {
  color: #fff !important;
}

.time-picker-selected-ampm div {
  color: #000 !important;
}

.time-picker-footer {
  button {
    background: transparent !important;
    text-transform: uppercase;
    font-weight: bold;
    color: #deaf2f;
  }
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.mat-simple-snackbar .mat-button-base {
  background-color: transparent;
}

.fc-disabled-day {
  opacity: 0.7 !important;
}

.default-theme .success-notification-overlay {
  color: $white;
}
