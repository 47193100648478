@import 'projects/brm-ui/assets/vars';

.analysis-entries {
  background-image: url('~src/assets/images/inventory/reservations-btn.png');
  background-repeat: no-repeat;
  background-position: right 5px top 5px;
  background-size: 30px 30px;
  border: 2px solid $green;
  padding: 10px;
  padding-right: 42px;
  border-radius: 3px;

  margin-bottom: $margin-btm;

  &.analysis-entries--product-line {
    background-image: url('~src/assets/images/inventory/shape/product-line-active.svg');
    border-color: $brm-pl-colour;
  }

  &.analysis-entries--product-family {
    background-image: url('~src/assets/images/inventory/shape/product-family-active.svg');
    border-color: $brm-pf-color;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      font-size: 0.9rem;
      text-decoration: underline;
      cursor: pointer;
      margin-right: 5px;

      &:not(:last-of-type) {
        &::after {
          content: ',';
        }
      }
    }

  }
}

.analysis-data {
  background-image: url('~src/assets/images/inventory/shape/product-item-active.svg');
  background-repeat: no-repeat;
  background-position: right 5px top 5px;
  background-size: 30px 30px;
  border: 2px solid $colour-product;
  padding: 10px 42px 10px 10px;
  border-radius: 3px;

  margin-bottom: $margin-btm;

  &.analysis-data--product-line {
    background-image: url('~src/assets/images/inventory/shape/product-line-active.svg');
    border-color: $brm-pl-colour;
  }
}
