.brm-expandable {
  padding: 5px;
  background-color: #f7f7f7;
  border: 1px solid #000;
  border-radius: 3px;

  > label {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
    margin-bottom: 0px;

    &:after {
      font-size: 1.1rem;
      content: '+';
    }
  }

  .brm-expandable__content {
    max-height: 0px;
    transition: all .3s;
    opacity: 0;
    overflow: hidden;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] {
    &:checked {

      + label {
        &:after {
          content: '—';
        }

        + .brm-expandable__content {
          max-height: 100vh;
          opacity: 1;
          overflow: initial;
        }
      }

    }
  }
}
