mat-form-field.price-group-input {

  .mat-input-element {
    flex: 1 1 150px;
  }

  .mat-form-field-label-wrapper {
    > label {
      // padding-left: 35px;
      // transition: all .4s;
    }
  }

  &.mat-focused, &.mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      > label {
        padding-left: 0px;
      }
    }
  }

  .price-group-input__warning {
    color: $orange;
    vertical-align: middle;

    &.price-group-input__warning--red {
      color: $red;
    }
  }
}


.price-group-input-option {

  &.price-group-input-option__new {
    color: $blue;
    font-size: 0.9rem;
  }

}
