.mat-input-element {

  &:disabled {
   color: map-get($brm-grey, 300);
  }
}

.time-picker-input.time-picker-input--today {
  .mat-form-field-underline {
    background-color: $orange !important;
    // border: 1px solid $orange !important;
  }
}

.brm-hide-input {
  input {
    display: none;
  }
}

.brm-date-input {
  pointer-events: none;
  cursor: default;
}

.mat-alt-input {
  font-size: 14px;

  .mat-form-field-flex {
    background: #dadce0 !important;
    border-radius: 4px !important;
  }

  .mat-form-field-infix {
    border: none !important;
    padding: 8px !important;
  }

  .mat-form-field-label {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 0px;
  }

  .mat-form-field-appearance-legacy, .mat-form-field-underline, .mat-form-field-subscript-wrapper {
    display: none !important;
  }

  .btn-div-options {
    .mat-select-value {
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      font-size: 14px;
    }
  }

  .event-type-options {
    .mat-select-value {
      font-size: 14px;
    }
  }
}

.rdp-mat-input {
  font-size: 14px;

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-flex {
    background: rgba(211, 211, 211, 0.5) !important;
    border-radius: 4px !important;
  }

  .mat-form-field-infix {
    border: none !important;
    padding: 8px !important;
  }

  .mat-form-field-label {
    padding-left: 8px;
  }

  .mat-form-field-appearance-legacy, .mat-form-field-underline, .mat-form-field-subscript-wrapper {
    display: none !important;
  }

  &.mat-focused {
    .mat-form-field-label {
      padding-left: 0px;
    }
  }
}