@import '~src/assets/css/variables';

$phone-button-height: $btn-height-sm;
$desktop-button-height: $btn-height-sm;

$lang-colour: #3072e3;
$success-colour: $colour-ocean-green;
$danger-colour: #FF4040;
$create-colour: #4A90E2;

.location-btn {
  cursor: pointer;
}

.def-btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;

  min-height: $phone-button-height;

  &.def-btn--full-width-mob {
    width: 100%;
  }

  &.def-btn-small--fsize {
    font-size: 1rem;
  }

  &.def-btn--align-with-input {
    margin-top: 5px;
  }

  &.def-btn--overflow-hidden {
    overflow: hidden;
  }

  &.def-btn--medium {
    min-height: $desktop-button-height;
    line-height: normal;
    min-width: auto;
  }

  &.def-btn--large {
    padding: 15px 40px;
  }

  &.def-btn--full-width {
    width: 100%;
  }

  &.def-btn--full-height {
    height: 100%;
  }

  mat-icon {
    font-size: 1rem;
    height: 24px;
    width: 24px;
    margin-right: 5px;
    vertical-align: sub;
  }

  &.def-btn--icon {
    text-align: center;

    mat-icon {
      height: 16px;
      width: 16px;
      margin-right: 2px;
    }
  }

  &.def-btn--align-middle {
    span {
      vertical-align: middle;
    }
  }

  &.def-btn--img-icon {
    padding-left: 28px;
    background-size: 16px auto;
    background-repeat: no-repeat;
    background-position: left 6px center;
  }

  &[disabled].def-btn--disabled {
    cursor: default;
    opacity: 0.3;

    opacity: 1;
    background-color: rgba(220, 220, 220, 0.5);
  }


  &:not([disabled]):not(.def-btn--disabled) {
    &.def-btn--orange {
      background-color: $orange;
      color: white;

      &:hover, &.def-btn--active {
        background-color: rgba($orange, 0.8);
      }
    }

    &.def-btn--black {
      background-color: $colour-secondary;
      color: white;

      &:hover, &.def-btn--active {
        background-color: rgba(#000, 0.8);
      }
    }

    &.def-btn--blue {
      background-color: $blue;
      color: white;

      &:hover, &.def-btn--active {
        background-color: rgba($orange, 0.8);
      }
    }

    &.def-btn--yellow {
      background-color: $yellow;
      color: $colour-black;

      &:hover, &.def-btn--active {
        background-color: rgba($yellow, 0.8);
      }
    }

    &.def-btn--red {
      background-color: $red;
      color: white;

      &:hover, &.def-btn--active {
        background-color: rgba($red, 0.8);
      }
    }

    &.def-btn--beige {
      background-color: $beige;
      color: $colour-black;

      &:hover, &.def-btn--active {
        background-color: rgba($beige, 0.8);
      }
    }

    &.def-btn--grey {
      background-color: map-get($brm-grey, 100);
      color: rgba(0, 0, 0, .87);

      &:hover, &.def-btn--active {
        background-color: map-get($brm-grey, 200);
      }
    }

    &.def-btn--dark-grey {
      background-color: #d0d0d0;
      color: rgba(0, 0, 0, .87);
    }

    &.def-btn--green {
      background-color: $colour-ocean-green;
      color: $colour-white;
    }

    &.def-btn--black-border {
      border-color: $colour-secondary;
    }

    &.def-btn--orange-border {
      border-color: $orange;
    }

    &.def-btn--green-border {
      border-color: $colour-ocean-green;
    }

    &.def-btn--red-border {
      border-color: $red;
    }

    &.def-btn--ls-red-border {
      border-color: $ls-red;
    }

    &.def-btn--blue-border {
      border-color: $blue;
    }

    &.def-btn--pl-purple-border {
      border-color: $brm-pl-colour;
    }
  }
}

.def-mat-menu-btn {
  &.def-mat-menu-btn--red {
    background-color: $red;
    color: $colour-white;

    &:hover, &.def-mat-menu-btn--active {
      background-color: rgba($red, 0.8);
    }
  }
}

/**
  DEF-BTN GROUP
 */

.def-btn-group {

  > button.def-btn {
    padding-left: 5px;
    padding-right: 5px;

    &:first-of-type {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0px;
    }

    &:last-of-type {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }

  }

}

@media (min-width: 992px) {
  .def-btn-group {
    > button.def-btn {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

/**
  END OF DEF-BTN-GROUP
 */

.live-btn {
  background-color: $create-colour;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.gold-btn {
  color: $colour-white;
  background-color: $gold;
  min-height: $phone-button-height;
}

.black-bordered-btn {
  border: 1px solid #000 !important;
}

.grey-btn {
  background-color: map-get($brm-grey, 100);
  color: rgba(0, 0, 0, .87);
}

.danger-btn {
  color: $colour-white;
  background-color: $danger-colour;
  min-height: $phone-button-height;
}

.create-btn {
  color: $colour-white;
  background-color: $create-colour;
  min-height: $phone-button-height;
}

.grey-btn {
  background-color: $grey;
  min-height: $phone-button-height;
}

.p1-btn {
  background-color: $colour-white;
  min-height: $phone-button-height;

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #283743;
  }
}

.p2-btn {
  border: 2px solid $success-colour !important;
  background-color: $colour-white;
  min-height: $phone-button-height;

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #283743;
  }
}

.p3-btn {
  background-color: $success-colour;
  color: $colour-white;
  min-height: $phone-button-height;
}


.lang-btn {
  background-color: $colour-white !important;
  border: 2px solid $lang-colour !important;
}

.success-btn {
  background-color: #28a745;
  color: $colour-white;
  min-height: $phone-button-height;
}

.full-width-mob-btn {
  width: 100%;
  margin-bottom: $margin-btm !important;
  min-height: $phone-button-height;
}

.brm-btn, .brm-clone-btn, .brm-add-btn, .brm-delete-btn, .brm-edit-btn, .brm-hidden-btn, .brm-add-alt-btn {
  background-position: center center;
  background-size: 27px 27px;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;

  display: inline-block;
  cursor: pointer;

  vertical-align: middle;

  border: none;
  background-color: transparent;
}

.brm-hidden-btn {
  cursor: default;
}

/***
  To avoid using JS, we add a class to the parent and we auto-change it to the intermediate image
 */
.brm-btn-hover {
  &:hover {
    .brm-delete-btn {
      background-image: url('~src/assets/images/aedpattern/delete-intermediate.svg');
    }

    .brm-edit-btn {
      background-image: url('~src/assets/images/aedpattern/edit-intermediate.svg');
    }

    .brm-add-alt-btn {
      background-image: url('~src/assets/images/aedpattern/add-alt-intermediate.svg');
    }

    .brm-add-btn {
      background-image: url('~src/assets/images/aedpattern/add-intermediate.svg');
    }

    .brm-clone-btn {
      background-image: url('~src/assets/images/aedpattern/clone-intermediate.svg');
    }
  }
}

.brm-btn-hover-targeted {
  &:hover {
    > .brm-btn-hover-targeted--target-parent {
      .brm-delete-btn {
        background-image: url('~src/assets/images/aedpattern/delete-intermediate.svg');
      }

      .brm-edit-btn {
        background-image: url('~src/assets/images/aedpattern/edit-intermediate.svg');
      }

      .brm-add-alt-btn {
        background-image: url('~src/assets/images/aedpattern/add-alt-intermediate.svg');
      }

      .brm-add-btn {
        background-image: url('~src/assets/images/aedpattern/add-intermediate.svg');
      }

      .brm-clone-btn {
        background-image: url('~src/assets/images/aedpattern/clone-intermediate.svg');
      }
    }
  }
}

.brm-clone-btn {
  background-image: url('~src/assets/images/clone-inactive.svg');

  &.brm-clone-btn--intermediate {
    background-image: url('~src/assets/images/aedpattern/clone-intermediate.svg');
  }

  &.brm-clone-btn--active, &:hover {
    background-image: url('~src/assets/images/clone-active.svg') !important;
  }


}

.brm-add-btn {
  background-image: url('~src/assets/images/aedpattern/add-inactive-white.svg');

  &.brm-add-btn--disabled, &.brm-add-btn--dark {
    background-image: url('~src/assets/images/aedpattern/add-inactive-dark.svg');
  }

  &.brm-add-btn--intermediate {
    background-image: url('~src/assets/images/aedpattern/add-intermediate.svg');
  }

  &:hover, &.brm-add-btn--active {
    background-image: url('~src/assets/images/aedpattern/add-active.svg') !important;
  }

  &--disabled {
    pointer-events: none;
  }
}

.brm-add-alt-btn {
  background-image: url('~src/assets/images/aedpattern/add-capcity-inactive.svg');
  background-size: 57px auto;

  &.brm-add-alt-btn--disabled, &.brm-add-alt-btn--dark {
    // background-image: url('~src/assets/images/aedpattern/add-inactive-dark.svg');
  }

  &.brm-add-alt-btn--intermediate {
    // background-image: url('~src/assets/images/aedpattern/add-alt-intermediate.svg');
  }

  &:hover, &.brm-add-alt-btn--active {
    background-image: url('~src/assets/images/aedpattern/add-capcity-active.svg') !important;
  }
}

.brm-delete-btn {
  background-image: url('~src/assets/images/aedpattern/delete-inactive-white.svg');

  &.brm-delete-btn--disabled, &.brm-delete-btn--dark {
    background-image: url('~src/assets/images/aedpattern/delete-inactive-dark.svg');
  }

  &.brm-delete-btn--intermediate {
    background-image: url('~src/assets/images/aedpattern/delete-intermediate.svg');
  }

  &:hover, &.brm-delete-btn--active {
    background-image: url('~src/assets/images/aedpattern/delete-active.svg') !important;
  }

}


.brm-edit-btn {
  background-image: url('~src/assets/images/aedpattern/edit-inactive-white.svg');

  &.brm-edit-btn--disabled, &.brm-edit-btn--dark {
    background-image: url('~src/assets/images/aedpattern/edit-inactive-dark.svg');
  }

  &.brm-edit-btn--intermediate {
    background-image: url('~src/assets/images/aedpattern/edit-intermediate.svg');
  }

  &:hover, &.brm-edit-btn--active {
    background-image: url('~src/assets/images/aedpattern/edit-active.svg') !important;
  }
}


.brm-filter-toggle-btn {
  border-radius: 20px;
  padding: 9px 13px;
  background-color: map-get($brm-grey, 100);
  color: $dark-primary-text;

  border: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 0.85rem;
  font-weight: 500;

  cursor: pointer;

  overflow: hidden;

  white-space: nowrap;

  &:before {
    content: '';

    display: block;

    background-color: map-get($brm-grey, 200);
    border-radius: 50%;
    margin-right: 7px;

    width: 20px;
    height: 20px;

    vertical-align: middle;

    flex-grow: 0;
    flex-shrink: 0;
  }

  &:hover {
    background-color: map-get($brm-grey, 300);
  }

  &.brm-filter-toggle-btn--active {
    background-color: $colour-primary;
    color: $colour-white;

    &:before {
      background-color: $colour-ocean-green;
    }
  }

  &.brm-filter-toggle-btn--grey-border {
    box-shadow: -5px 0 5px -2px rgba(map-get($brm-grey, 300), 85%);
  }

  &.brm-filter-toggle-btn--black-border {
    box-shadow: -5px 0 5px -2px rgba(map-get($brm-grey, 900), 85%);
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .full-width-mob-btn {
    width: auto;
    margin-bottom: 0px !important;
  }

  .def-btn {
    &.def-btn--full-width-mob {
      width: auto;
    }
  }

  .success-btn {
    height: $desktop-button-height;
  }
}
