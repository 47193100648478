@import '~material-community-components/theming';

// colour tidy up: riley, 16/03/2022
// new colour variables prefixed with `$colour-`
// name colours according to https://chir.ag/projects/name-that-color/ to create standardised naming conventions
$colour-white: #fff;

// reds
$colour-cinnabar: #e6474b; // BRM Primary Red (700)
$colour-sunglo: #EB6C6F; // red 500
$colour-mandy: #E9595D; // red 600
$colour-flush-mahogany: #CF4044; // red 800
$colour-medium-carmine: #B8393C; // red 900

// greys
$colour-iron: #D6D8D8; // grey 100
$colour-silver-sand: #C1C4C5; // grey 200
$colour-olso-grey: #83898B; // grey 300
$colour-mako: #454E51; // grey 600
$colour-outer-space: #303A3E; // BRM Secondary Grey (700) 'Charcoal'
$colour-outer-space2: #2B3438; // grey 800
$colour-outer-space3: #262E32; // grey 900
$colour-gallery: #eee;
$colour-whitesmoke: #F5F5F5;
$colour-alto: #D3D3D3;
$colour-alabaster: #FBFBFB;

// other colours
$colour-ocean-green: #4cba70;
$colour-bright-sun: #fbd635;
$colour-horizon: #5f7eaa;
$colour-deep-cerulean: #008c99;
$colour-stripe: #4379ff;
$colour-jordy-blue: #9ab6ff;
$colour-surf-crest: #d3e8d3;
$colour-black-09:  rgba(1, 1, 1, 0.9);
$colour-black-087: rgba(0, 0, 0, 0.87);
$colour-black-026: rgba(0, 0, 0, .26);
$colour-black-01: rgba(0, 0, 0, 0.1);


// add semantic variables so colours can be interpreted per use and updated if needed
$colour-black: $colour-outer-space3;
$colour-default-text: $colour-black;

$colour-primary: $colour-cinnabar;
$colour-primary-hover: $colour-flush-mahogany;
$colour-primary-active: $colour-mandy;
$colour-warning: $colour-medium-carmine;

$colour-secondary: $colour-outer-space;

$colour-fieldset-border: $colour-iron;
$colour-highlight: $colour-jordy-blue;

// Colour-coding for some areas/features
$colour-reservations: $colour-ocean-green;
$colour-category: $colour-horizon;
$colour-product: $colour-bright-sun;
$colour-reservation-search: $colour-deep-cerulean;
$colour-reservation-search-hover: darken($colour-reservation-search, 10%);
$colour-reservation-search-active: lighten($colour-reservation-search, 10%);

// Reservation stage colours
$colour-reservation-confirmed: #1208fb;
$colour-reservation-checked-in: $colour-ocean-green;
$colour-reservation-checked-out: $colour-warning;
$colour-reservation-cancelled: #616161;
$colour-reservation-booking-req: #dc983e;
$colour-reservation-partial-check-in: #b31e45;

// Angular Material Palettes from brand colours
$brm-red: (
  25: #fff1f2,
  50: #FFE2E3,
  100: #F5B5B7,
  200: #F3A3A5,
  300: #F09193,
  400: #EE7E81,
  500: #EB6C6F,
  600: $colour-mandy,
  700: $colour-cinnabar, // Primary
  800: $colour-flush-mahogany,
  900: $colour-medium-carmine,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$brm-grey: (
  25: #F8F8F8,
  50: #EAEBEC,
  100: $colour-iron,
  200: $colour-silver-sand,
  300: $colour-olso-grey,
  400: #6E7578,
  500: #596165,
  600: $colour-mako,
  700: $colour-outer-space, // Primary
  800: $colour-outer-space2,
  900: $colour-outer-space3,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$brm-product-palette: (
  100: $colour-product,
  500: $colour-product,
  700: $colour-product,
  contrast: (
    500: $dark-primary-text,
  )
);

$brm-reservation-palette: (
  100: $colour-reservations,
  500: $colour-reservations,
  700: $colour-reservations,
  contrast: (
    500: $light-primary-text,
  )
);

$brm-category-palette: (
  100: $colour-category,
  500: $colour-category,
  700: $colour-category,
  contrast: (
    500: $light-primary-text,
  )
);

$brm-reservation-search-palette: (
  100: $colour-reservation-search,
  500: $colour-reservation-search,
  700: $colour-reservation-search,
  contrast: (
    500: $light-primary-text,
  )
);


// legacy colours that have been updated with new variables
// TODO: refactor these out to use semantic colour names
$white: $colour-white;
$grey: $colour-gallery;
$red: $colour-warning;
$ls-red: $colour-sunglo;
$grey-nav: $colour-outer-space;
$green: $colour-ocean-green;
$green-pale: lighten($colour-ocean-green, 10%);
$dark-green: darken($colour-ocean-green, 10%);
$orange: $colour-primary;

$colour-havelock-blue: #4a90e2;
$blue: $colour-havelock-blue;


// Legacy colours used in the app
// TODO: assign new colours from out more limited palette
$grey-focused: #bebebe;
$grey-dark: #e0e0e0;
$grey-darker: #d4d4d4;
$grey-darker-again: #b0b0b0;
$grey-input: rgba(211, 211, 211, 0.5);
$black-pale: #8b8b8b;
$gold: #deaf2f;
$red-pale: #ff9f95;
$blue-pale: #9ab6ff;
$beige: #ecead6;
$yellow:  #ffc107;

$brm-table-header-bg-color: rgb(252, 252, 252);
$brm-table-accented-bg-color: rgb(248, 248, 248);
$brm-table-row-hover-bg-color: rgb(243, 243, 243);
$brm-table-border-color: rgba(0, 0, 0, .12);

// bootstrap
$bootstrap-blue: #007bff;

$brm-pl-colour: #c590ec;
$brm-pf-color: #2cbbc1;

$black-overlay: rgba($colour-black, 0.2);
$black-overlay-darker: rgba($colour-black, 0.75);

// box shadow
@mixin BoxShadowDepth($level: 1){
    @if $level == 1 {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
    @else if $level == 2 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    @else if $level == 4 {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    @else if $level == 5 {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
}

// -- Measurements -- //
// when we have to separate two elements vertically, this is the normal value we separate them
$margin: 10px;
$margin-btm: $margin;
$margin-left: $margin;
$margin-right: $margin;

$padding: 10px;
$normal-padding: $padding;

$border-radius: 5px;

// -- Fonts -- //
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

//Buttons
$btn-border-radius-sm: 5px;
$btn-border-radius-md: 6px;
$btn-border-radius-lg: 8px;
$btn-border-radius: $btn-border-radius-md;

$btn-padding-x-sm: 14px;
$btn-padding-x-md: 18px;
$btn-padding-x-lg: 20px;
$btn-padding-x: $btn-padding-x-md;
$btn-padding-y-sm: 12px;
$btn-padding-y-md: 14px;
$btn-padding-y-lg: 16px;
$btn-padding-y: $btn-padding-y-md;

$btn-height-sm: 33px;
$btn-height-md: 38px;
$btn-height-lg: 44px;
$btn-height: $btn-height-md;

$btn-font-size-sm: 12px;
$btn-font-size-md: 14px;
$btn-font-size-lg: 16px;
$btn-font-size: $btn-font-size-md;

$btn-default-bg: $colour-outer-space3;
$btn-default-color: $light-primary-text;
$btn-defualt-border: $light-primary-text;

$av-input-height: 35px;


// Keyframes
@keyframes progress {
  100% {
    right: 100%;
  }
}

// Z-Index System
@function ZIndex($level: base){
  $zIndex: map-get((
    base: 0,
    bottom: -1,
    low: 1,
    medium: 100,
    top: 11000,
  ), $level);

  @return $zIndex;
}

@mixin ZIndexSystem($level: base){
  z-index: ZIndex($level);
}

// Breakpoint system
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$grid-bp-xs: map-get($grid-breakpoints, "xs");
$grid-bp-sm: map-get($grid-breakpoints, "sm");
$grid-bp-md: map-get($grid-breakpoints, "md");
$grid-bp-lg: map-get($grid-breakpoints, "lg");
$grid-bp-xl: map-get($grid-breakpoints, "xl");
$grid-bp-xxl: map-get($grid-breakpoints, "xxl");

