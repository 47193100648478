.Anchor {
  &--settingsIcon,
  &--externalIcon{
    &::after {
      display: inline-block;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      vertical-align: text-top;
      padding-left: 2px;
      margin-top: 2px;
    }
  }

  &--settingsIcon {
    &::after {
      content: 'settings';
    }
  }

  &--externalIcon {
    &::after {
      content: 'launch';
    }
  }
}

// For embedded FreshSales articles, where they contain a link to an external page, add the 'launch' icon
app-freshsales-article {
  a[target="_blank"] {
    &::after {
      content: 'launch';
      display: inline-block;
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      vertical-align: text-top;
      padding-left: 2px;
      margin-top: 2px;
    }
  }
}
