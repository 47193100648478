.lightspeed-icon {
  background-image: url("~src/assets/images/logos/ls-icon.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.jdi-icon {
  background-image: url('~src/assets/images/jdi/jdi-hammer-angle-white.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 150% auto;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;

  &.jdi-icon--white-angle {
    background-image: url('~src/assets/images/jdi/jdi-hammer-angle-black.svg');
  }
}
