.search-activated {
  .brm-menu {
    .menu {
      &__title {
        opacity: .2;
      }
    }
    .menu-items {
      &__item {
        &:not(.active) {
          opacity: .2;
        }
      }
    }
  }
}
.brm-cog-menu {
  position: absolute;
  top: 4px;
  right: 3px;
  z-index: 3;
  width: 25px;
  height: 25px;
  background-position: center bottom -4px;
  background-size: 35px auto;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("~src/assets/images/button-icons/cog-icon.svg");
  cursor: pointer;
  border-radius: 50%;
}

.brm-sort-menu {
  position: absolute;
  top: 4px;
  left: 3px;
  z-index: 3;
  width: 25px;
  height: 25px;
  background-position: center bottom -4px;
  background-size: 35px auto;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  border-radius: 50%;
}

.brm-reservation-menu {
  position: absolute;
  top: 4px;
  right: 67px;
  z-index: 3;
  width: 25px;
  height: 25px;
  background-position: center bottom -4px;
  background-size: 35px auto;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.8);
  // background-image: url("~src/assets/images/button-icons/cog-icon.svg");
  cursor: pointer;
  border-radius: 50%;

  mat-icon {
    height: 18px;
    vertical-align: middle;
    font-size: 1.1rem;
    color: rgb(145, 145, 145);
  }
}

.brm-print-option {
  position: absolute;
  top: 4px;
  right: 35px;
  z-index: 3;
  width: 25px;
  height: 25px;

  cursor: pointer;
  border-radius: 50%;
  color: gray;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;

  mat-icon {
    height: 18px;
    vertical-align: middle;
    font-size: 1.1rem;
    color: rgb(145, 145, 145);
  }

  &.brm-print-option-non-abs {
    position: static;
    top: initial;
    right: initial;
    z-index: initial;
  }
}

.waiver-icon-inline, .terms-icon-inline {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;

  display: inline-block;

  vertical-align: middle;


  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url('~src/assets/images/terms-icon.svg');
}

.waiver-icon-inline {
  background-image: url('~src/assets/images/waiver-icon.svg');
}

.menu-waiver-icon, .menu-terms-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url('~src/assets/images/terms-icon.svg');
}

.menu-waiver-icon {
  background-image: url('~src/assets/images/waiver-icon.svg');
}

.brm-menu {

  .menu {
    .menu__title {
      padding-bottom: 2px;
      // margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.menu__title--pointer {
        cursor: pointer;
      }

      &.menu__title--red {
        color: red;
        border-bottom: 1px solid red;
      }

      &.menu__title--blue {
        color: blue;
        border-bottom: 1px solid blue;
      }
    }
  }

  .menu-items {
    margin-bottom: 10px;

    &.menu-items--collapsible {
      max-height: 0px;
      overflow-y: hidden;
      transition: max-height 0.5s;
      user-select: none;
    }

    &.menu-items--expanded {
      max-height: 650px;
    }

    .menu-items__item {
      color: inherit;
      // border: 1px solid #000;
      width: 100%;
      min-height: 90px;
      padding: 10px 20px;
      font-weight: bold;
      // background-color: #d2d5d4;
      // border-radius: 3px;
      cursor: pointer;

      flex: 0 0 150px;
      text-align: center;
      margin-bottom: 10px;

      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      position: relative;
      overflow: hidden;

      &.menu-items__item--smaller-text {
        font-size: 0.95rem;
      }

      .menu-item-tick {
        height: 24px;
        color: #28a745;

        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        text-decoration: none;
        background-color: #b7bab9;
      }

      &.menu-items__item--disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.4);
        background-color: #a6a9a8;

        &:hover {
          background-color: #a6a9a8;
        }
      }

      &.menu-items__item--todo {
        background-color: #c0edf3;
      }

      &.menu-items__item--todo-greyed {
        background-color: #a6a9a8;
        cursor: not-allowed;
      }

      &.menu-items__item--pending {
        color: white;
        background-color: #5ba95a;
      }

      &.menu-items__item--done {
        border: #28a745 4px solid;
      }

      &.menu-items__item--upgrade {
        border: 4px solid #dc983e;
      }

      &.menu-items__item--same-height {
        min-height: 102px;
      }

      .menu-item-content {


        &.menu-item-background {
          padding-top: 40px;
          background-repeat: no-repeat;
          background-position: center -20px;
          background-size: auto 80px;
          background-image: url('~src/assets/images/start-icons/add-bikes.svg');

          &.menu-item-background--generic-inventory {
            background-image: url('~src/assets/images/sidenav/inventory-dark.svg');
          }

          &.menu-item-background--delivery {
            background-position: center -19px;
            background-image: url('~src/assets/images/start-icons/delivery.svg');
          }

          &.menu-item-background--pricing {

            background-image: url('~src/assets/images/start-icons/pricing.svg');
          }

          &.menu-item-background--search {
            background-size: auto 42px;
            background-position: center top;
            background-image: url('~src/assets/images/start-icons/search.svg');
          }

          &.menu-item-background--storesettings {
            background-image: url('~src/assets/images/sidenav/settings-dark.svg');
          }

          &.menu-item-background--hours {
            background-image: url('~src/assets/images/start-icons/hours.svg');
          }

          &.menu-item-background--hoursclosed {
            background-image: url('~src/assets/images/start-icons/hours-closed.svg');
          }

          &.menu-item-background--notifications {
            background-image: url('~src/assets/images/notifications/notifications_who.svg');
          }

          &.menu-item-background--notifications-when {
            background-image: url('~src/assets/images/notifications/notifications_when.svg');
          }

          &.menu-item-background--notifications-content {
            background-image: url('~src/assets/images/notifications/notifications_content.svg');
          }

          &.menu-item-background--notifications-advanced {
            background-image: url('~src/assets/images/notifications/notifications_advanced.svg');
          }

          &.menu-item-background--reports-graphs {
            background-image: url('~src/assets/images/reports/graphs.svg');
            background-size: 35px 90px;
          }

          &.menu-item-background--reports-transactions {
            background-image: url('~src/assets/images/reports/transactions.svg');
            background-size: 35px 90px;
          }

          &.menu-item-background--reports-exportdata {
            background-image: url('~src/assets/images/reports/exportdata.svg');
            background-size: 35px 90px;
          }

          &.menu-item-background--onbikerent {
            background-image: url('~src/assets/images/start-icons/on-bike-rent.svg');
          }

          &.menu-item-background--onlinechannels {
            background-size: 65px auto;
            background-position: center top -10px;
            background-image: url('~src/assets/images/channels.png');
          }

          &.menu-item-background--onyoursite {
            background-image: url('~src/assets/images/start-icons/on-your-site.svg');
          }

          &.menu-item-background--liveinstore {
            background-image: url('~src/assets/images/start-icons/live-in-store.svg');
          }

          &.menu-item-background--customers {
            background-image: url('~src/assets/images/sidenav/customers-dark.svg');
          }

          &.menu-item-background--storedetails {
            background-image: url('~src/assets/images/sidenav/settings-dark.svg');
          }

          &.menu-item-background--slack {
            background-image: url('~src/assets/images/slack-new-logo.svg');
            background-size: 40px 40px;
            background-position: center top;
          }

          &.menu-item-background--lightspeed {
            background-image: url('~src/assets/images/lightspeed.svg');
            background-size: 105px 105px;
            background-position: center center;
          }

          &.menu-item-background--mailchimp {
            background-image: url('~src/assets/images/logos/mailchimp.png');
            background-position: center center;
            background-size: contain;
          }

          &.menu-item-background--twilio {
            background-image: url('~src/assets/images/logos/twilio.svg');
            background-position: center center;
            background-size: contain;
          }

          &.menu-item-background--loqate {
            background-image: url('~src/assets/images/logos/loqate-logo.svg');
            background-position: center center;
            background-size: 90px auto;
          }

          &.menu-item-background--paymentmethods {
            background-image: url('~src/assets/images/start-icons/payment-methods.svg');
          }

          &.menu-item-background--paymentprocessors {
            background-image: url('~src/assets/images/start-icons/payment-processors.svg');
          }

          &.menu-item-background--offeringcontrol {
            background-image: url('~src/assets/images/start-icons/offering-control.svg');
          }

          &.menu-item-background--multilocation {
            background-image: url('~src/assets/images/start-icons/multilocation.svg');
          }

          &.menu-item-background--reservationsettings {
            background-image: url('~src/assets/images/start-icons/reservation-settings.svg');
          }

          &.menu-item-background--reservationtypes {
            background-image: url('~src/assets/images/start-icons/reservation-types.svg');
          }

          &.menu-item-background--maintenance {
            background-image: url('~src/assets/images/start-icons/maintenance.svg');
          }

          &.menu-item-background--publish {
            background-image: url('~src/assets/images/start-icons/publish.svg');
          }

          &.menu-item-background--languages {
            background-image: url('~src/assets/images/start-icons/languages.svg');
          }

          &.menu-item-background--extras {
            background-image: url('~src/assets/images/start-icons/extras.svg');
          }

          &.menu-item-background--events {
            background-image: url('~src/assets/images/start-icons/events.svg');
          }

          &.menu-item-background--advanced {
            background-image: url('~src/assets/images/start-icons/advanced.svg');
          }

          &.menu-item-background--pickup {
            background-image: url('~src/assets/images/limit.svg');
          }

          &.menu-item-background--discountcodes {
            background-image: url('~src/assets/images/start-icons/discount-codes.svg');
          }

          &.menu-item-background--itemcompatibility {
            background-image: url('~src/assets/images/start-icons/item-compatibility.svg');
          }

          &.menu-item-background--plans {
            background-image: url('~src/assets/images/start-icons/plans-and-bills.svg');
          }

          &.menu-item-background--users {
            background-image: url('~src/assets/images/start-icons/user.svg');
          }
        }

        .menu-item-content__title {
          word-break: break-word;
        }

        .menu-item-content__negative, .menu-item-content__positive {
          font-size: 0.75rem;
        }

        .menu-item-content__negative {
          color: #e48027
        }

        .menu-item-content__positive {
          color: #28a745;
        }

      }

    }
  }

}

.menu-item-title-icon {
  width: 20px;
  height: 20px;
  display: inline-block;

  vertical-align: middle;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 175% auto;
  background-image: url('~src/assets/images/notification.svg');

  &.no-background {
    background-image: none;
    display: none;
  }

  &.menu-item-title-icon--integrations {
    background-image: url('~src/assets/images/integration.svg');
  }

  &.menu-item-title-icon--online {
    background-image: url('~src/assets/images/online-booking.svg');
  }

  &.menu-item-title-icon--account {
    background-image: url('~src/assets/images/account.svg');
    background-size: 100%;
  }

  &.menu-item-title-icon--payment {
    background-image: url('~src/assets/images/payment.svg');
    background-size: 100%;
  }

  &.menu-item-title-icon--beam {
    background-image: url('~src/assets/images/self-registration.svg');
  }

  &.menu-item-title-icon--general {
    background-image: url('~src/assets/images/general.svg');
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .brm-menu {

    .menu-items {
      display: flex;
      flex-wrap: wrap;

      .menu-items__item {
        width: auto;
        margin-right: 20px;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}


/***
ANGULAR MENUS
 */

.mat-checkbox-layout {
  margin-bottom: 0px;
}
