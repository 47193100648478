@use '@angular/material' as mat;
@use '~src/assets/css/theme' as brm-theme;

    .mat-button-base {
        border-radius: $btn-border-radius;
        font-size: $btn-font-size;
        height: $btn-height; //Don't want to use height here, but using it for now as icons within buttons are supersizing the butons.
        padding: 0 $btn-padding-y;

        &.mat-stroked-button:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
            border-color: $btn-default-bg;
            color: $btn-default-bg;

            &[disabled] {
                border-color: rgba($btn-default-bg,0.2);
                color: rgba($btn-default-bg,0.2);
            }
        }

        &.mat-button--sm {
            font-size: $btn-font-size-sm;
            height: $btn-height-sm; //Don't want to use height here, but using it for now as icons within buttons are supersizing the butons.
            padding: 0 $btn-padding-y-sm;
        }

        &.mat-button--lg {
            font-size: $btn-font-size-lg;
            height: $btn-height-lg; //Don't want to use height here, but using it for now as icons within buttons are supersizing the butons.
            padding: 0 $btn-padding-y-lg;
        }

        &.mat-button--full-width {
            width: 100%;
        }

        mat-icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
            vertical-align: middle;
        }

        &.mat-icon-button {
            background-color: transparent;
            border-color: transparent;
            border-radius: 50%;
            height: auto;
            padding: 0;

            &:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
                color: currentColor;
            }
        }

        &[disabled],
        &.mat-flat-button[disabled],
        &.mat-raised-button[disabled],
        &.mat-icon[disabled],
        &.mat-button-disabled[disabled],
        &.mat-stroked-button[disabled] {
            background-color: rgba($btn-default-bg,0.2);
            color: rgba($dark-primary-text, 0.4);
        }

        &.mat-icon-button.mat-primary, &.mat-stroked-button.mat-primary {
            color: mat.get-color-from-palette(brm-theme.$brm-primary);
        }

        &.mat-icon-button.mat-accent, &.mat-stroked-button.mat-accent {
            &:not(:disabled) {
                color: mat.get-color-from-palette(brm-theme.$brm-reservation);
            }
        }
    }

    a[mat-stroked-button].mat-stroked-button:not(.mat-button-disabled),
    a[mat-stroked-button].mat-stroked-button:not(.mat-button-disabled).mat-accent,
    button.mat-stroked-button:not(.mat-button-disabled),
    button.mat-stroked-button:not(.mat-button-disabled).mat-accent,
    label.mat-button-base.mat-stroked-button:not(.mat-button-disabled) {
        border-width: 2px;
        border-color: currentColor;
        background-color: transparent;

        &[disabled] {
            border-color: rgba($btn-default-bg,0.2);
            color: rgba($btn-default-bg,0.2);
        }
    }

.mat-button-toggle {
    .mat-button-toggle-button {
        outline: none;
    }
}
