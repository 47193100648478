@import '../../../assets/css/variables';

$confirmed-col: #1208fb;
$checked-in-col: $colour-ocean-green;
$checked-out-col: $colour-warning;
$booking-req-col: #dc983e;
$booking-cancelled: #616161;

.reservations-stage {
  padding: 3px 5px;
  border-radius: 20px;
  background-color: $colour-black !important;
  color: white !important;
  min-width: 100px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: $margin-btm;

  justify-content: center !important;

  &.reservation-stage--width-auto {
    width: auto;
  }

  &.reservation-stage--min-width-auto {
    width: auto;
  }

  &.reservation-stage--height-auto {
    height: auto;
  }

  &.reservation-stage--margin-right {
    margin-right: $margin-right;
  }

  &.reservation-stage--no-margin-btm {
    margin-bottom: 0;
  }

  &.reservation-stage--margin-top {
    margin-top: 4px;
  }

  &.reservations-stage--confirmed, &[lozenge='confirmed'] {
    background-color: $confirmed-col !important;
  }

  &.reservations-stage--checked-in, &[lozenge='checked_in'] {
    background-color: $checked-in-col !important;
  }

  &.reservations-stage--checked-out, &[lozenge='checked_out'] {
    background-color: $checked-out-col !important;
  }

  &.reservations-stage--booking-request, &[lozenge='booking_request'] {
    background-color: $booking-req-col !important;
  }

  &.reservations-stage--booking-request, &[lozenge='cancelled'] {
    background-color: $booking-cancelled !important;
  }

  &.reservations-stage--booking-request, &[lozenge='partial_check_in'] {
    background-color: #b31e45 !important;
  }
}

.reservations-type {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 100%;
  margin: 0 auto;
}

mat-form-field[lozenge] {

  &.mat-focused .mat-select-arrow, .mat-select-arrow {
    color: white !important;
  }

  .mat-form-field-flex {
    background-color: $colour-black !important;
    color: white;
    line-height: normal;

    .mat-select-value {
      color: white;
    }
  }

  &[lozenge='confirmed'] .mat-form-field-flex {
    background-color: $confirmed-col !important;;
  }

  &[lozenge='checked_in'] .mat-form-field-flex {
    background-color: $checked-in-col !important;;
  }

  &[lozenge='checked_out'] .mat-form-field-flex {
    background-color: $checked-out-col !important;;
  }

  &[lozenge='booking_request'] .mat-form-field-flex {
    background-color: $booking-req-col !important;;
  }
}

.reservation-tabulator-image {
  width: 100%;
  height: 100%;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;

  &.reservation-tabulator-image--delivery {
    background-image: url('~src/assets/images/reservations/rlp/delivery.png');
  }

  &.reservation-tabulator-image--stripe {
    background-image: url('~src/assets/images/reservations/rlp/stripe.png');
  }

  &.reservation-tabulator-image--notes {
    background-image: url('~src/assets/images/reservations/rlp/notepad.gif');
  }
}
