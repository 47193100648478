@import '~src/assets/css/variables';

.Card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.mat-card {
    display: flex;
  }

  &-header {
    flex: 0;
    width: 100%;
    min-height: 35px;
    margin-bottom: $margin-btm;
    border-bottom: 1px solid $colour-olso-grey;

    &--noUnderline {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  &-title {
    font-weight: bold;

    &--fullWidth {
      width: 100%;
    }

    > mat-icon {
      font-size: 1rem;
      height: 16px;
      vertical-align: top;
      width: 16px;
      line-height: inherit;
    }
  }

  &-titleUnderline {
    height: 0;
    width: 100%;
    border: 0;
    border-bottom: 1px solid $colour-olso-grey;
    padding: 0;
    margin: 0 0 $margin-btm;
  }

  &-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    height: 100%;
    width: 100%;

    background-color: $black-overlay;
  }

  &-content {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: $margin;

    &--centred {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
