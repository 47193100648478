.lozenge-tag {
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: bold;
  display: inline-block;

  position: relative;

  vertical-align: middle;

  padding: 5px 8px 5px 25px;

  border: 2px solid #fb0735;
  background-color: #fb0735;
  color: white;

  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  overflow: hidden;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    border-radius: 50%;
    background-color: white;
    content: '';
    width: 13px;
    height: 13px;

    user-select: none;
  }

  &.lozenge-tag--margin-btm {
    margin-bottom: $margin-btm;
  }

  &.lozenge-tag--reservation-stage-green {
    background-color: $colour-ocean-green;
    border-color: $colour-ocean-green;
  }

  &.lozenge-tag--gold {
    background-color: $gold;
    border-color: $gold;
  }

  &.lozenge-tag--blue {
    background-color: $blue-pale;
    border-color: $blue-pale;
  }

  &.lozenge-tag--orange {
    background-color: $orange;
    border-color: $orange;
  }

  &.lozenge-tag--red-outline {
    background-color: white;
    border-color: $red;
    color: $red;

    &:before {
      background-color: $red;
    }
  }
}
