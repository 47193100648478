@import '~src/assets/css/variables';

.FilterChips {
  .FilterChips-chip {
    &.mat-chip.mat-standard-chip {
      border-radius: 6px;
      border: 1px solid $colour-olso-grey;
      background-color: transparent;
      color: $colour-olso-grey;

      &:before {
        transition: font-size 0.3s ease-in-out;
        content: 'check';
        display: inline-block;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        vertical-align: text-top;
        padding-right: 2px;
        // We transition from font-size:0 -> initial so we get a nice animation
        font-size: 0;
      }

      &.mat-chip-selected {
        &.mat-accent {
          background-color: transparentize($colour-ocean-green, 0.7);
          color: $colour-mako;
        }

        &:before {
          // Font size transition here on chip selection
          font-size: 1.2em;
        }
      }
    }
  }
}
