@import '~src/assets/css/variables';

.brm-mat-table-preview {
  display: flex;
  width: 100%;

  td:first-of-type {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    align-items: center;
  }

  td:nth-of-type(1) {
    width: 65px;
  }

  td:nth-of-type(n + 3) {
    width: 115px;
  }
}

.brm-table {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: $margin-btm;
  margin-left: -15px;
  margin-right: -15px;

  &.brm-table--padding {
    margin-left: 0px;
    margin-right: 0px;

    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }

  .brm-table__header {
    text-align: center;
    background-color: #dee2e6;
    font-size: 0.75rem;
    font-weight: 500;

    &.brm-table__header--secondary-header {
      background-color: #d3d7db;
    }

    [class*='col-'] {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .brm-table__loader {
    font-size: 0.8rem;
    text-align: center;
    border-bottom: 1px #dee2e6 solid;

    padding-top: 16px;
    padding-bottom: 8px;

    mat-progress-spinner {
      margin: 0 auto;
    }

    [class*='col-'] {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 35px;
      min-height: 35px;
    }
  }
}

.brm-table-wrap {
  overflow-x: hidden;
  overflow-y: auto;

  &.brm-table-wrap--50vh {
    max-height: 50vh;
  }

  &.brm-table-wrap--40vh {
    max-height: 40vh;
  }

  &.brm-table-wrap--30vh {
    max-height: 30vh;
  }

  &.brm-table-wrap--20vh {
    max-height: 20vh;
  }

  &.brm-table-wrap--10vh {
    max-height: 10vh;
  }

}

/**
  Have to take this out for CDKDrag
 */
.brm-table__data {
  font-size: 0.8rem;
  text-align: center;
  border-bottom: 1px #dee2e6 solid;

  padding-top: 8px;
  padding-bottom: 8px;

  &.brm-table__data--no-select {
    user-select: none;
  }


  &.brm-table__data--pointer {
    cursor: pointer;
  }

  &.brm-table__data--align-left {
    text-align: left;
  }

  &.brm-table__data--align-right {
    text-align: right;
  }

  &:nth-of-type(2n + 1) {
    background-color: rgba(0, 0, 0, 0.02);
  }

  &.cdk-drag-preview {
    background-color: rgba(#f1f1f1, 0.65);
    border: 1px #dee2e6 solid;
  }

  &:hover {
    background-color: #f1f1f1;
  }

  &.brm-table__data--empty {
    cursor: initial;

    &:hover {
      background-color: inherit;
    }
  }

  &.brm-table__data--selected {
    background-color: $blue-pale;
  }

  [class*='col-'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 35px;
    min-height: 35px;
  }
}

.brm-boot-table {
  border-left: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;

  &.brm-boot-table--abs {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 10;

    tr {
      td, th {
        text-align: center;
        background-color: #dee2e6;
        font-size: 0.8rem;
        font-weight: 500;
      }

      th {
        position: sticky;
        top: 0;
      }
    }
  }

  tbody {
    tr {
      td, th {
        font-size: 0.8rem;
        text-align: center;
        border-bottom: 1px #dee2e6 solid;
      }

      &:nth-of-type(2n) {
        background-color: rgba(0, 0, 0, 0.02);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }

    }
  }
}

.extra-img {
  width: 100%;
  height: 100%;
  min-height: 50px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

tr.brm-boot-table-drag-preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  td, th {
    font-size: 0.8rem;
    text-align: center;

    padding: 0.35rem !important;

    vertical-align: middle;
  }
}

.brm-boot-table-wrap {
  position: relative;
  height: 100%;
  width: 100%;

  overflow-y: auto;

  border-left: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;

  background-color: #f9f9f9;

  min-height: 400px;

  .brm-boot-table {
    border: none;
    height: auto !important;

    tbody {
      tr {
        td, th {
          font-size: 0.8rem;
          text-align: center;
          border-bottom: 1px #dee2e6 solid;
        }
      }
    }
  }
}

.brm-mat-table {
  width: 100%;

  min-height: 150px;

  position: relative;

  .brm-mat-table__loader {
    background-color: $black-overlay;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 10;
  }

  table {
    border-left: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;

    width: 100%;

    thead {
      tr {
        td, th {
          position: sticky;
          top: 0;

          text-align: center;
          background-color: #dee2e6;
          font-size: 0.75rem;
          font-weight: 500;

          .mat-sort-header-container {
            justify-content: center;
            color: #000;
          }
        }
      }
    }

    tbody {
      tr {
        td, th {
          font-size: 0.8rem;
          text-align: center;
          border-bottom: 1px #dee2e6 solid;
        }

        &:nth-of-type(2n + 1) {
          background-color: rgba(0, 0, 0, 0.02);
        }

        &:hover {
          background-color: #f1f1f1;
        }

      }
    }

  }
}
