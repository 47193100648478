.no-res-image {
  width: 100%;
  background-position: center center;
  background-size: auto 100%;
  background-image: url('~src/assets/images/no-reservations.svg');
  height: 140px;
  background-repeat: no-repeat;
  margin-bottom: $margin-btm;
}

.no-res-message {

}

.no-res-highlight {
  color: $orange;
  font-weight: bold;
}
