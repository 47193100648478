.tabulator-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .tabulator-calcs-holder {
    width: calc(100% + 20px) !important;
    flex: 0 0 100%;

    &.tabulator-calcs-holder--hide {
      display: none;
    }
  }

  .tabulator-paginator {
    flex: 1 1;
    min-width: 350px;
  }
}

.tabulator-cell-overflow {
  overflow: visible !important;
}

.description-overridden {
  color: $blue;
}

.tabulator-footer-link {
  color: $bootstrap-blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.tabulator-loader {
  display: none !important;
}

#tabulator-full-data-warning {
  color: $grey-darker-again;
  font-style: italic;
  font-weight: bold;
  font-size: 0.9rem;
  flex: 1 1;
  min-width: 100px;
  display: none;
}

#tabulator-total-count {
  flex: 0 0;
  width: 100px;
}


.item-price-overridden {
  padding: 2px 3px;
  border: 1px solid $green;
  border-radius: 3px;
}

.red-cell {
  background-color: $red;
  color: #fff;
}
