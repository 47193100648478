.mat-form-field-no-padding.mat-form-field .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}
.mat-form-field-slim.mat-form-field-appearance-fill .mat-form-field-flex {
  padding-top: 0;
}
.mat-form-field-slim.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 0 0.75em 0;
}
.mat-form-field-slim.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: none;
}

.mat-form-field-slim-mini.mat-form-field-appearance-fill {
  .mat-form-field-infix {
    padding: 0 0 0.35em 0;
    border-top-width: 0.4em;
  }

  .mat-form-field-flex {
    margin-top: 20px;
  }

  .mat-form-field-suffix {
    position: absolute;
    right: 0;
    top: 21px;
  }

  .mat-form-field-label-wrapper {
    margin-top: -8px;
  }

  .mat-form-field-label {
    transform: translateY(0);
    padding-left: 0;
  }

  .mat-chip-list {
    .mat-chip-list-wrapper {
      > :not(:first-child).mat-chip-input {
        width: 50px;
        flex: 1 0 50px;
      }
    }
  }

  .mat-chip-input {
    margin-top: 4px;
  }
}

.mat-form-field {
  .mat-button-base {
    background-color: transparent;
    color: currentColor;
  }
}
