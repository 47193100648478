@import 'assets/css/bootstrap/bootstrap';
@import 'assets/css/brm';

@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '../node_modules/@fullcalendar/list/main.css';
@import '../node_modules/@fullcalendar/timeline/main.css';
@import '../node_modules/@fullcalendar/resource-timeline/main.css';

@import '~tabulator-tables/src/scss/themes/semanticui/tabulator_semanticui.scss';

@import '../node_modules/prismjs/themes/prism.css';

@import '3rd-party';

html,
body {
  width     : 100vw;
  overflow-x: hidden;
}

body {
  margin     : 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.test-tooltip {
  // max-width: none !important;
}

snack-bar-container.success-notification {
  background-color: #60c143;

  .mat-button-wrapper {
    color: #ccf2bd !important;
  }
}

snack-bar-container.warning-notification {
  background-color: #e2a33d;

  .mat-button-wrapper {
    color: #ffd6b3 !important;
  }
}

snack-bar-container.fail-notification {
  background-color: #ba2930;
  color: $colour-white;

  .mat-button-wrapper {
    color: #e49999 !important;
  }
}

.green-but {
  background-color: #28a745;
  color           : white;

  &.green-but--stroked {
    color           : #28a745;
    background-color: white;
    border          : 3px solid #28a745;
  }
}

.ns {
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select    : none;
  /* Non-prefixed version, currently */
}

mat-progress-spinner.btn-spinner {
  margin: 0 auto;

  circle {
    stroke: white;
  }
}

mat-progress-spinner.help-spinner {
  margin: 0 auto;

  circle {
    stroke: #c5c5c5;
  }
}

.full-width {
  width: 100%;
}

.flex-row {
  display: flex;

  >[class*='col-'] {
    display       : flex;
    flex-direction: column;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}
