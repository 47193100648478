.mat-menu-header {
  color: grey;
  font-size: 0.9rem;
  user-select: none;
  cursor: default;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 30px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
