.lang-highlight {

  .mat-form-field-label {
    animation: language-highlight-text 2s 1;
  }

  .mat-form-field-infix {
    animation: language-highlight-background 2s 1;
  }

  .angular-editor-textarea {
    // color: rgb(124, 157, 198);
    animation: language-highlight-text 2s 1, language-highlight-background 2s 1;
  }

  .angular-editor-placeholder {
    animation: language-highlight-text-white 2s 1, language-highlight-text 2s 1, language-highlight-background 2s 1;
  }

  .mat-form-field-ripple, .mat-form-field-underline {
    animation: language-highlight-underline 2s 1;
  }

}

.brm-language-field--highlight {

  .mat-form-field-label {
    animation: language-highlight-text 2s 1;
  }

  .mat-form-field-infix {
    animation: language-highlight-background 2s 1;
  }

  .angular-editor-textarea {
    // color: rgb(124, 157, 198);
    animation: language-highlight-text 2s 1, language-highlight-background 2s 1;
  }

  .angular-editor-placeholder {
    animation: language-highlight-text-white 2s 1, language-highlight-text 2s 1, language-highlight-background 2s 1;
  }

  .mat-form-field-ripple, .mat-form-field-underline {
    animation: language-highlight-underline 2s 1;
  }

}

.reservation-highlight {
  animation: reservation-highlight-background 2s 1;
}

@keyframes language-highlight-text {
  0%, 100% {

  }

  50% {
    // color: rgb(124, 157, 198);
    font-weight: 500;
  }
}

@keyframes language-highlight-text-white {
  0%, 100% {

  }

  50% {
    color: #FFF;
  }
}

@keyframes language-highlight-underline {
  0%, 100% {
    // background-color: inherit;
  }

  50% {
    background-color: rgb(173, 194, 219);
  }
}

@keyframes language-highlight-background {
  0%, 100% {

  }

  50% {
    background-color: rgb(222, 230, 239);
  }
}

@keyframes reservation-highlight-background {
  0%, 100% {

  }

  50% {
    background-color: $green;
  }
}
