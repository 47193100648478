.brm-badge {

  &.brm-badge--orange {

    .mat-badge-content {
      font-size: 9px;
      background-color: $orange;
    }

  }

}
