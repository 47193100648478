@import '~src/assets/css/variables';

$border-tab-color: rgba(0, 0, 0, .12);
$border-vert-sep-tab-color: rgba(0, 0, 0, .3);

.brm-table-opts {
  &.brm-table-opts--vertical-top {
    table {
      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }

  table {

    tr {

      td {
        &.brm-table-col-vertical-middle {
          vertical-align: middle;
        }
      }

    }

  }
}

.brm-scroll-table-paginator {
  border-bottom: 1px solid $brm-table-border-color;
  border-left: 1px solid $brm-table-border-color;
  border-right: 1px solid $brm-table-border-color;
}

.brm-table-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $black-overlay;
}

.brm-flex-scroll-table {
  position: relative;

  height: 100%;
  width: 100%;

  overflow: auto;

  border-bottom: 1px solid $brm-table-border-color;
  border-left: 1px solid $brm-table-border-color;
  border-right: 1px solid $brm-table-border-color;

  table {
    width: 100%;
    border-collapse: initial;

    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;

    tr {
      &:first-of-type {
        th {
          border-top: 1px solid $brm-table-border-color;
          background-color: $brm-table-header-bg-color;
        }
      }

      &:last-of-type {
        td {
          border-bottom: none;
        }
      }

      &:nth-of-type(2n) {
        background-color: $brm-table-accented-bg-color;
      }

      td, th {
        border-right: 1px solid $border-vert-sep-tab-color;

        padding: 6px 10px;

        &:first-of-type, &:last-of-type {
          padding-left: 10px;
          padding-right: 10px;
        }

        &:last-of-type {
          border-right: none;
        }

        &.brm-table-center-content {
          text-align: center;
        }
      }

      &:hover {
        td {
          background-color: $brm-table-row-hover-bg-color;
        }
      }
    }

    th {
      vertical-align: bottom;
      padding-bottom: 8px;
    }
  }
}

.mat-paginator-range-actions {
  button:disabled.mat-button-base.mat-icon-button:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
    background-color: unset;
    color: $colour-black-026;
  }
  button:focus {
    outline: none;
  }
}
