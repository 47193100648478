.brm-chip {

  &.brm-chip--sku {
    background-color: #000;
    color: #FFF;
  }

  &.brm-chip--price-group {
    background-color: $orange;
    color: #FFF;
  }

  &.brm-chip--brand {
    background-color: #a6b3bf;
    color: #000;
  }

}

.brm-chip-list {

  .brm-chip {
    background-color: #000;
    color: #FFF;
    padding: 4px 6px;
    border-radius: 4px;
    display: inline-block;
    font-size: 0.9rem;
    min-height: 29px;
    vertical-align: middle;

    &.brm-chip--bold {
      font-weight: bold;
    }

    &.brm-chip--pointer {
      cursor: pointer;
    }


    &.brm-chip--disabled {
      background-color: #e0e0e0;
    }

    &.brm-chip--outline {
      background-color: transparent;
      border: 2px solid #000;
      color: #000;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    mat-icon {
      vertical-align: middle;
      font-size: 1.1rem;
      height: auto;
      width: 20px;
    }
  }

}
