.brm-asset-status-icon {
  width: 29px;
  height: 23px;

  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  background-image: url('~src/assets/images/asset-status-icons/ready.svg');

  display: inline-block;

  vertical-align: middle;

  &.brm-asset-status-icon--large {
    width: 44px;
    height: 30px;
  }

  &.brm-asset-status-icon--broken, &[type='broken'] {
    background-image: url('~src/assets/images/asset-status-icons/broken.svg');
  }

  &.brm-asset-status-icon--ready, &[type='ready'] {
    background-image: url('~src/assets/images/asset-status-icons/ready.svg');
  }

  &.brm-asset-status-icon--suspended, &[type='suspended'] {
    background-image: url('~src/assets/images/asset-status-icons/suspended.svg');
  }

  &.brm-asset-status-icon--stolen, &[type='stolen'] {
    background-image: url('~src/assets/images/asset-status-icons/stolen.svg');
  }

  &.brm-asset-status-icon--sold, &[type='sold'] {
    background-image: url('~src/assets/images/asset-status-icons/sold.svg');
  }

  &.brm-asset-status-icon--transferred, &[type='transferred'] {
    background-image: url('~src/assets/images/asset-status-icons/transferred.svg');
  }

  &.brm-asset-status-icon--ordered, &[type='ordered'] {
    background-image: url('~src/assets/images/asset-status-icons/ordered.svg');
  }

  &.brm-asset-status-icon--missing, &[type='missing'] {
    background-image: url('~src/assets/images/asset-status-icons/missing.svg');
  }

  &.brm-asset-status-icon--checked-out, &[type='checked_out'] {
    background-image: url('~src/assets/images/asset-status-icons/checked_out.svg');
  }

  &.brm-asset-status-icon--archived, &[type='archived'] {
    background-image: url('~src/assets/images/asset-status-icons/suspended.svg');
  }
}
