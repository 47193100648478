.brm-popper {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 0.7rem;
  font-weight: 500;

  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

  -webkit-transform: scale(1.0, 1.0) translateZ(0);
  backface-visibility: hidden !important;
  -webkit-font-smoothing: subpixel-antialiased !important;

  z-index: 1000;

  &.brm-popper--fixed-tab {
    position: fixed;
    transform: translate3d(5px, 85px, 0px) !important;
  }

  &.brm-popper--inventory-icon {
    z-index: 7;
  }
}
