@import '~src/assets/css/variables';

.dialog-button-header {
  margin-bottom: $margin-btm;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dialog-button-header__close-btn {
    color: #000;
    font-size: 1.5rem;

    min-width: 0;
    width: auto;
    line-height: initial;

    padding: 0;
    outline: 0;

    mat-icon {
      vertical-align: top;
    }

    .mat-button-wrapper {
      height: 24px;
      display: block;
    }
  }

  .dialog-button-header__just-do-it {
    background-color: $red;
    color: white;
    padding: 3px 8px;

    font-size: 0.9rem;
    border-radius: 3px;
    margin-right: $margin-right;

    cursor: pointer;
    text-decoration: none;

    user-select: none;
  }

  .dialog-button-header__draft {
    background-color: $colour-black;
    color: white;
    padding: 3px 8px;

    font-size: 0.9rem;
    border-radius: 3px;
    margin-right: $margin-right;

    cursor: pointer;
    text-decoration: none;

    user-select: none;
  }

  &.dialog-button-header--title-and-close {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: 0px;
    }
  }
}

.dialog-danger {
  border: 4px solid $red;
  border-radius: 3px;

  h1 {
    color: $red;
  }
}

.dialog-header {
  font-size: 1.5rem;
  display: flex !important;
  justify-content: space-between;
  margin-bottom: $margin-btm;

  .header-icon {
    margin-right: $margin-right;
    vertical-align: middle;
  }

  span {
    align-self: center;
  }

  .dialog-header__close-btn {
    color: #000;
    font-size: 1.5rem;

    min-width: 0;
    width: auto;
    line-height: initial;

    padding: 0;
    outline: 0;

    align-self: baseline;

    mat-icon {
      vertical-align: top;
    }

    .mat-button-wrapper {
      height: 24px;
      display: block;
    }
  }

  h1 {
    margin-bottom: 0px;
  }
}

.dialog-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-content-wrap {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dialog-content {
  flex: 1;
  height: 100%;
  margin-bottom: $margin-btm;
}

.dialog-buttons {
  flex: 0;

  button:not(:first-of-type) {
    margin-left: $margin-left;
  }

  .dialog-buttons__right-btns {
    text-align: right;
  }
}
