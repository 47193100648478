@import '../../../src/assets/css/variables';

.fc-view-container {
  height: 100%;
}

.fc-event {
  cursor: pointer;
}


/***
    CUSTOMISED FC ELEMENTS
 ***/

.fc-highlight {
  background-color: rgba($colour-highlight, 0.9) !important;
}

.fc-row-resource {
  cursor: pointer;

  &.fc-row-resource--highlight {
    td {
      background-color: rgba($colour-highlight, 0.82);
      font-weight: bold;
    }
  }

  &.fc-row-resource--active {
    td {
      background-color: $colour-highlight;
    }
  }

  &.fc-row-resource--asset-in-use {
    td {
      background-color: $green-pale;
    }
  }

  &.fc-row-resource--not-available {
    td {
      background-color: $red-pale;
    }
  }

  &.fc-row-resource--product-line {
    td {
      background-color: $brm-pl-colour;
    }
  }

  &.fc-row-resource--category {
    td {
      background-color: $colour-category;
    }
  }

  &.fc-row-resource--asset-broken {
    td {
      background-color: $black-pale;
      text-decoration: line-through;
    }
  }

  &.fc-row-resource--asset-suspended {
    cursor: pointer;

    td {
      background-color: $black-pale;

      .fc-cell-text {

      }
    }
  }

  &.fc-row-resource--asset-suspended {
    td {

    }
  }

}

.fc-time-element {

  &.fc-time-element--non-focus {
    background-color: rgba($grey, 0.4);
  }

  &.fc-time-element--today {
    background-color: rgba(#fcf8e3, 0.8);
  }

}


.asset-table {

  .fc-header-toolbar {
    margin: 0;
  }

}

.reservation-invalid {
  background-image: linear-gradient(45deg, #000000 25%, #998a26 25%, #998a26 50%, #000000 50%, #000000 75%, #998a26 75%, #998a26 100%);
}

.reservation-invalid-tooltip {
  font-weight: bold;
  color: $red;
}

/**
    Classes for handling product line activity
 */

.pl-activity-event {
  background-color: #769BCC;
  border: 3px solid #769BCC;
  text-align: center;

  &.pl-activity-event--green {
    background-color: #37804d;
    border-color: #37804d;
  }

  &.pl-activity-event--amber {
    background-color: $orange;
    border-color: $orange;
  }

  &.pl-activity-event--red {
    background-color: #b02315;
    border-color: #b02315;
  }


  &.pl-activity-event--has-virtual {
    border-color: $brm-pl-colour;
  }

}
