/*.mat-slide-toggle {
  &.mat-checked .mat-slide-toggle-thumb {
    background-color: $green !important;
  }

  &.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($green, 0.54) !important;
  }

  &.mat-checked .mat-ripple-element {
    background-color: $green !important;
  }

  &.mat-slide-toggle--red {
    &.mat-checked .mat-slide-toggle-thumb {
      background-color: $red !important;
    }

    &.mat-checked .mat-slide-toggle-bar {
      background-color: rgba($red, 0.54) !important;
    }

    &.mat-checked .mat-ripple-element {
      background-color: $red !important;
    }
  }
}*/


.mat-slide-toggle {
  &.mat-checked .mat-slide-toggle-thumb {
    background-color: $green;
  }

  &.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($green, 0.54);;
  }

  &.mat-checked .mat-ripple-element {
    background-color: $green;;
  }

  &.mat-slide-toggle--red {
    &.mat-checked .mat-slide-toggle-thumb {
      background-color: $red;
    }

    &.mat-checked .mat-slide-toggle-bar {
      background-color: rgba($red, 0.54);
    }

    &.mat-checked .mat-ripple-element {
      background-color: $red;
    }
  }
}
