.brm-star {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  &.brm-star--active {
    cursor: default;
    color: $orange;
  }
}
