.mat-alt-select {
    font-size: 14px;
  
    &.mat-alt-select--no-padding-btm {
      .mat-form-field-wrapper {
        padding-bottom: 0px;
      }
    }
  
    .mat-form-field-flex {
      background: #dadce0 !important;
      border-radius: 4px !important;
      min-height: 35px !important;
    }
  
    .mat-form-field-wrapper {
      margin-top: 10px;
    }
  
    .mat-form-field-infix {
      border: none !important;
      padding: 8px !important;
    }
  
    .mat-form-field-label {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 0px;
    }
  
    .mat-form-field-appearance-legacy, .mat-form-field-underline {
      display: none !important;
    }
  
    .mat-form-field-subscript-wrapper {
      padding-left: 6px;
      padding-right: 6px;
      margin-top: .25em;
    }
  
    .btn-div-options {
      .mat-select-value {
        color: rgba(0, 0, 0, 0.6);
        font-weight: bold;
        font-size: 14px;
      }
    }
  
    .event-type-options {
      .mat-select-value {
        font-size: 14px;
      }
    }
  }
  
  .mat-disabled-chevron-hide {
    .mat-select-arrow {
      visibility: hidden;
    }
  }
  
  .mat-select-panel-down {
    min-width: 100% !important;
    position: absolute;
    top: 25px;
    left: -32px;
    /*
    margin-left: 18px;
    margin-top: 25px;
  */
  }