// UserGuiding branding overrides
a.ug-branding-anchor {
  visibility: hidden !important;

  &::after {
    display: block;
    content: 'Bike.Rent Manager';
    width: 100%;
    height: 30px;
    visibility: visible;
    position: absolute;
    top: 0;
  }
}
